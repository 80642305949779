import { Box, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const NoAccessView = () => {
  const { t } = useTranslation('translations');

  const supportMail = 'support@kulturit.org';
  const dmUrl = 'https://digitaltmuseum.org/021015683216';
  const imgLabel = t('auth.imgLabel');

  return (
    <Box
      padding='80px 16px 16px 16px'
      height='100vh'
      width='100vw'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h3' sx={{ paddingBottom: '32px' }}>{t('auth.noAccessHeader')}</Typography>
      <Typography>{t('auth.noAccessContactSupport')}</Typography>
      <Link href={`mailto:${supportMail}`}>{supportMail}</Link>
      <Divider width='460px' sx={{ my: '32px' }} />
      <Link href={dmUrl} target="_blank" rel="noopener">
        <img src='/wash.webp' alt={imgLabel}/>
      </Link>
      <Typography variant="subtitle">
        {imgLabel}
      </Typography>
    </Box>
  )
}