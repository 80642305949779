import { CheckCircleTwoTone, HighlightOffTwoTone } from "@material-ui/icons";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { TvattLoader } from "../components/TvattLoader/TvattLoader";
import { usePrimusState } from "../contexts/PrimusContext";
import {
  COLLECTIONS_WRITE_RIGHT_ID,
  GLOBAL_LISTS_RIGHTS_ID,
  LOOKUP_LISTS_RIGHTS_ID,
  NAMES_COLLECTION_ID,
  NAMES_RIGHTS_ID, rightsMap
} from "../declarations/UUIDs";
import { PrimusApi } from "../services/PrimusApi";

const necessaryRights = {
  [LOOKUP_LISTS_RIGHTS_ID]: false,
  [GLOBAL_LISTS_RIGHTS_ID]: false,
  [NAMES_RIGHTS_ID]: false,
};

const necessaryCollections = {
  [NAMES_COLLECTION_ID]: false,
}

export const PrimusRights = ({ children }) => {
  const { t } = useTranslation('translations');
  const { baseUrl } = usePrimusState();

  const [allRights, setAllRights] = useState({ ...necessaryRights, ...necessaryCollections });
  const [loading, setLoading] = useState(true);
  const handleRights = (user) => {
    let tempRights = necessaryRights;
    user.rights?.forEach((right) => {
      if (tempRights.hasOwnProperty(right.user_rights_id)) {
        tempRights[right.user_rights_id] = true;
      }
    });
    let tempCollections = necessaryCollections;
    user.collections?.forEach((collection) => {
      if (tempCollections.hasOwnProperty(collection.collection_id)) {
        tempCollections[collection.collection_id] = collection.collection_rights_id === COLLECTIONS_WRITE_RIGHT_ID;
      }
    })
    setAllRights({ ...tempRights, ...tempCollections });
  }

  useEffect(() => {
    if (!!baseUrl) {
      const primus = new PrimusApi(baseUrl);
      primus.getUser()
        .then((userConfig) =>
          !!userConfig?.user?.artifact_id && primus.getArtifact(userConfig.user.artifact_id)
            .then((user) => !!user && handleRights(user)))
        .then(() => setLoading(false));
    }
  }, [baseUrl])

  if (loading) {
    return <TvattLoader fullscreen show />
  }

  return (
    !!Object.values(allRights).every(value => value === true)
      ? children
      : (
        <Box display='flex' flexDirection='column' alignItems='center' marginTop='32px' gap='4px' height='100%'>
          <Typography variant='h6' sx={{ marginBottom: '24px' }}>
            {t('primusRights.header')}
          </Typography>
          {Object.entries(allRights).map(([k, v]) => (
            <Box display='flex' key={k} gap='8px' width='420px' justifyContent='space-between'>
              <Typography>{t(rightsMap[k])}</Typography>
              {!!v ? <CheckCircleTwoTone htmlColor='green' /> : <HighlightOffTwoTone htmlColor='red' />}
            </Box>
          ))}
        </Box>
      )
  );
}
